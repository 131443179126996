import {Tabs} from '@dropbox/dig-components/tabs';
import {Text} from '@dropbox/dig-components/typography';
import {DisclaimerType} from 'client';
import {DisclaimerModal} from 'components/disclaimer_modal/disclaimer_modal';
import {
  FeedbackController,
  useGetFeedback,
} from 'components/manager_feedback/feedback';
import {FeedbackFilters} from 'components/manager_feedback/feedback_filters';
import {
  FeedbackQuotesController,
  useGetFeedbackQuotes,
  useGetFeedbackSummary,
} from 'components/manager_feedback/feedback_quotes';
import {useQueryParamState} from 'hooks/use_query_params';
import React from 'react';
import {DropboxerId} from 'store/features/feedback_chat/types';

import styles from './manager_feedback.module.css';

const usePrefetchTabData = (dropboxerId: DropboxerId) => {
  useGetFeedback(dropboxerId);
  useGetFeedbackQuotes(dropboxerId);
  useGetFeedbackSummary(dropboxerId);
};

const FEEDBACK_TAB_IDS: readonly string[] = [
  'all_feedback',
  'organized_feedback',
] as const;

const FeedbackTabs = ({dropboxerId}: {dropboxerId: DropboxerId}) => {
  const [selectedTabQueryParam, setSelectedTab] = useQueryParamState('tab');

  const selectedTab =
    selectedTabQueryParam && FEEDBACK_TAB_IDS.includes(selectedTabQueryParam)
      ? selectedTabQueryParam
      : FEEDBACK_TAB_IDS[1];

  React.useEffect(() => {
    if (selectedTab !== selectedTabQueryParam) {
      setSelectedTab(selectedTab);
    }
  }, [selectedTab, selectedTabQueryParam, setSelectedTab]);

  usePrefetchTabData(dropboxerId);

  return (
    <Tabs
      selectedTab={selectedTab}
      onSelection={setSelectedTab}
      className={styles['content-container']}
    >
      <Tabs.Group>
        <Tabs.Tab id="all_feedback">All Feedback</Tabs.Tab>
        <Tabs.Tab id="organized_feedback">GPT Organized Feedback</Tabs.Tab>
      </Tabs.Group>
      <Tabs.Panel tabId="all_feedback" className={styles['tabs-panel']}>
        <FeedbackController dropboxerId={dropboxerId} />
      </Tabs.Panel>
      <Tabs.Panel tabId="organized_feedback" className={styles['tabs-panel']}>
        <FeedbackQuotesController dropboxerId={dropboxerId} />
      </Tabs.Panel>
    </Tabs>
  );
};

export const ManagerFeedbackAssistant = () => {
  const [selectedDropboxerId, setSelectedDropboxerId] =
    useQueryParamState('eid');

  return (
    <div className={styles['page']}>
      <DisclaimerModal disclaimerType={DisclaimerType.MANAGER_ASSISTANT} />
      <FeedbackFilters
        selectedDropboxerId={selectedDropboxerId}
        onSelectDropboxer={setSelectedDropboxerId}
      />
      {selectedDropboxerId ? (
        <FeedbackTabs dropboxerId={selectedDropboxerId} />
      ) : (
        <Text
          variant="paragraph"
          tagName="p"
          className={styles['content-container']}
        >
          Select a dropboxer to view their feedback
        </Text>
      )}
    </div>
  );
};

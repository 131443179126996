const SPRITES_CYCLE_2024_NAME = '2024 SPRiTEs Feedback';
const SPRITES_CYCLE_2023_NAME = '2023 SPRiTEs Feedback';
const SPRITES_CYCLE_2022_NAME = '2022 SPRiTEs Feedback';
const SPRITES_CYCLE_2021_NAME = '2021 SPRiTEs Feedback';
const SPRITES_CYCLE_2020_NAME = '2020 SPRiTEs Feedback';

// MAKE SURE YOU MATCH THE DATA FOUND IN /sprites-gpt-server/api/endpoints/open_ai.py
const spriteDateRanges = [
  {
    start: new Date('2024-02-24'),
    end: new Date('2025-02-14'),
    spriteCycle: SPRITES_CYCLE_2024_NAME,
  },
  {
    start: new Date('2023-01-24'),
    end: new Date('2024-02-23'),
    spriteCycle: SPRITES_CYCLE_2023_NAME,
  },
  {
    start: new Date('2022-01-06'),
    end: new Date('2023-01-23'),
    spriteCycle: SPRITES_CYCLE_2022_NAME,
  },
  {
    start: new Date('2021-01-09'),
    end: new Date('2022-01-05'),
    spriteCycle: SPRITES_CYCLE_2021_NAME,
  },
  {
    start: new Date('2020-01-11'),
    end: new Date('2021-01-08'),
    spriteCycle: SPRITES_CYCLE_2020_NAME,
  },
];

const feedbackTypeToSpritesCycle = (feedbackType: string) => {
  if (feedbackType.includes('2024')) {
    return SPRITES_CYCLE_2024_NAME;
  } else if (feedbackType.includes('2023')) {
    return SPRITES_CYCLE_2023_NAME;
  } else if (feedbackType.includes('2022')) {
    return SPRITES_CYCLE_2022_NAME;
  } else if (feedbackType.includes('2021')) {
    return SPRITES_CYCLE_2021_NAME;
  } else if (feedbackType.includes('2020')) {
    return SPRITES_CYCLE_2020_NAME;
  }

  return null;
};

const dateToSpriteCycle = (dateStr: string) => {
  const date = new Date(dateStr);

  for (const spriteDateRange of spriteDateRanges) {
    if (date >= spriteDateRange.start && date <= spriteDateRange.end) {
      return spriteDateRange.spriteCycle;
    }
  }

  return null;
};

export const getSpriteCycleOfFeedback = (
  feedbackType: string,
  feedbackDate: string
) => {
  // Use the `feedbackType` as the first way of determining which Sprite Cycle the feedback corresponds
  // to. If we can't determine the Sprite Cycling using `feedbackType`, fall back to using `feedbackDate`.
  return (
    feedbackTypeToSpritesCycle(feedbackType) || dateToSpriteCycle(feedbackDate)
  );
};

import {Button, IconButton} from '@dropbox/dig-components/buttons';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CollapseLeftLine,
  EarlyBirdLine,
  ExpandRightLine,
  PaperDocLine,
  PersonMultipleLine,
} from '@dropbox/dig-icons/assets';
import cx from 'classnames';
import {ROUTE_PATHS} from 'constant';
import {useMobile} from 'hooks/isMobile';
import {useAuthUserIsAdmin} from 'hooks/use_is_admin';
import {useDoesUserHaveAnyReports} from 'hooks/use_reports';
import {useNavigateToTop} from 'hooks/useNavigateToTop';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import styles from './LeftNav.module.css';

interface ItemProps {
  name: string;
  path: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  isActive: boolean;
  isLeftNavCollapsed: boolean;
}

const Item = ({name, icon, path, isActive, isLeftNavCollapsed}: ItemProps) => {
  const navigateToTop = useNavigateToTop();
  const navigateAndReset = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    to: string
  ) => {
    event.preventDefault();
    navigateToTop(to);
  };

  const iconAndText = (
    <Button
      variant="borderless"
      size="standard"
      withIconStart={<UIIcon src={icon} className={styles['icon']} />}
      className={cx(
        isActive ? styles['item-button-active'] : styles['item-button']
      )}
      href={path}
      onClick={(e) => {
        navigateAndReset(e, path);
      }}
    >
      {name}
    </Button>
  );

  const iconAndTooltip = (
    <Tooltip placement="right" title={name}>
      <IconButton
        variant="borderless"
        size="standard"
        shape="circular"
        className={cx(
          isActive ? styles['item-button-active'] : styles['item-button'],
          styles['item-icon-button']
        )}
        href={path}
        onClick={(e) => {
          navigateAndReset(e, path);
        }}
      >
        <UIIcon src={icon} className={styles['icon']} />
      </IconButton>
    </Tooltip>
  );

  return isLeftNavCollapsed ? iconAndTooltip : iconAndText;
};

export const LeftNav = () => {
  const isMobile = useMobile();
  const location = useLocation();

  const doesUserHaveAnyReports = useDoesUserHaveAnyReports();
  const isAdmin = useAuthUserIsAdmin();

  const baseNavItems = [
    {
      id: 'feedbackV2',
      text: 'Feedback Assistant',
      path: `${ROUTE_PATHS.FEEDBACK}`,
      icon: PersonMultipleLine,
    },
    {
      id: 'career_frameworks',
      text: 'Career Frameworks',
      path: `${ROUTE_PATHS.CAREER_FRAMEWORK}`,
      icon: PaperDocLine,
    },
  ];

  const usersWithReportsNavItems = [
    {
      id: 'manager_feedback',
      text: 'Manager Feedback Assistant',
      path: `${ROUTE_PATHS.MANAGER_FEEDBACK}`,
      icon: EarlyBirdLine,
    },
  ];

  const navItemOrder = ['feedbackV2', 'manager_feedback', 'career_frameworks'];
  const navItems = [
    ...baseNavItems,
    ...(doesUserHaveAnyReports || isAdmin ? usersWithReportsNavItems : []),
  ].sort((a, b) => navItemOrder.indexOf(a.id) - navItemOrder.indexOf(b.id));

  const [isLeftNavCollapsed, setisLeftNavCollapsed] = useState(() => {
    const savedValue = JSON.parse(
      localStorage.getItem('isLeftNavCollapsed') ?? 'false'
    );
    return savedValue || isMobile || false;
  });

  useEffect(() => {
    localStorage.setItem(
      'isLeftNavCollapsed',
      JSON.stringify(isLeftNavCollapsed)
    );
  }, [isLeftNavCollapsed]);

  return (
    <div className={styles['left-nav']}>
      <div className={styles['items']}>
        {navItems.map((item) => (
          <Item
            key={item.id}
            name={item.text}
            icon={item.icon}
            path={item.path}
            isActive={location.pathname == item.path}
            isLeftNavCollapsed={isLeftNavCollapsed}
          />
        ))}
      </div>
      {!isMobile && (
        <div className={styles['collapse-button']}>
          <IconButton
            variant="transparent"
            size="small"
            shape="circular"
            className={styles['item-button']}
            onClick={() => setisLeftNavCollapsed(!isLeftNavCollapsed)}
          >
            <UIIcon
              src={isLeftNavCollapsed ? ExpandRightLine : CollapseLeftLine}
              className={styles['icon']}
              size="medium"
            />
          </IconButton>
        </div>
      )}
    </div>
  );
};

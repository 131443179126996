import {ApiError} from 'client';
import {CareerFrameworkMetadata} from 'client';
import {DropboxerResponse} from 'components/dropboxer_search/dropboxer_search';
import {useThrottleAsyncCallback} from 'hooks/use_throttle';
import React from 'react';
import {store} from 'store';
import {
  getCareerFrameworkIndividualsFromSessions,
  getDropboxersFromSessions,
  getFeedbackAssistantSessions,
  getFeedbackChatState,
} from 'store/features/feedback_chat/selectors';
import {feedbackChatActions} from 'store/features/feedback_chat/slice';
import {
  CareerFrameworkIndividual,
  CareerFrameworkIndividuals,
  Dropboxer,
  Dropboxers,
  Individual,
  IndividualId,
  SelectedIndividualInfo,
  TypesOfIndividual,
} from 'store/features/feedback_chat/types';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getOpenAiService} from 'utilities';

export const useStartChatCallback = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    async ({
      individualId,
      individual,
    }: {
      individualId?: string;
      individual?: Individual;
    }) => {
      if (!individualId) {
        return;
      }

      dispatch(
        feedbackChatActions.newSession({
          individual,
          individualId,
        })
      );

      try {
        const {
          session_id,
          message,
          career_framework_id,
          previous_career_framework_id,
        } = await getOpenAiService().startChatApiV1ChatStartGet(individualId);

        dispatch(
          feedbackChatActions.newSession({
            sessionId: session_id,
            promptId: message.id,
            firstMessageText: message.message,
            individual,
            individualId,
            careerFrameworkId: career_framework_id,
            previousCareerFrameworkId: previous_career_framework_id,
          })
        );

        dispatch(
          feedbackChatActions.addMessageInputBox({
            individualId,
          })
        );

        dispatch(
          feedbackChatActions.updateSessionExpiration({
            individualId,
            expirationTime: Date.now() + 24 * 60 * 60 * 1000,
            historyExpirationTime: Date.now() + 30 * 24 * 60 * 60 * 1000,
          })
        );
      } catch (error) {
        console.log(error);

        if (error instanceof ApiError && error.status === 403) {
          console.log({error});
          dispatch(
            feedbackChatActions.newSession({
              firstMessageText: error.body?.detail,
              individualId,
            })
          );
          return;
        }

        dispatch(
          feedbackChatActions.newSession({
            firstMessageText:
              'Oops, something went wrong. Please select another Dropboxer or Career Framework Track.',
            individualId,
          })
        );
      }
    },
    [dispatch]
  );
};

export const useEditMessageCallback = (individualId?: IndividualId) => {
  const dispatch = useAppDispatch();
  return React.useCallback(
    (message: string) => {
      if (!individualId) {
        return;
      }

      dispatch(
        feedbackChatActions.editUnsentUserMessage({message, individualId})
      );
    },
    [dispatch, individualId]
  );
};

export const useSendMessageCallback = ({
  sessionId,
  currentPromptId,
}: {
  sessionId?: string;
  currentPromptId?: string;
}) => {
  const dispatch = useAppDispatch();
  const {selectedIndividualInfo, sessions} =
    useAppSelector(getFeedbackChatState);
  const individualInfo = selectedIndividualInfo ?? 'default';
  const individualId =
    individualInfo == 'default' ? 'default' : individualInfo.individualId;
  const expirationTime = sessions[individualId].expirationTime ?? 0;

  return React.useCallback(
    async (message: string): Promise<void> => {
      if (!sessionId || !currentPromptId || !selectedIndividualInfo) {
        return;
      }

      if (Date.now() > expirationTime) {
        dispatch(
          feedbackChatActions.removeUnsentMessage({
            individualId: selectedIndividualInfo.individualId,
          })
        );
        dispatch(
          feedbackChatActions.newPendingBotMessage({
            individualId: selectedIndividualInfo.individualId,
          })
        );
        dispatch(
          feedbackChatActions.updatePendingBotMessage({
            text: `It has been 24 hours since your last message, so this chat session is closed.

To re-start this session, click Start Over. Just be aware that you'll lose all existing messages in this chat.`,
            individualId: selectedIndividualInfo.individualId,
          })
        );
        return;
      }

      dispatch(
        feedbackChatActions.markUnsetMessageAsSent({
          individualId: selectedIndividualInfo.individualId,
        })
      );
      dispatch(
        feedbackChatActions.newPendingBotMessage({
          individualId: selectedIndividualInfo.individualId,
        })
      );

      try {
        const response =
          await getOpenAiService().continueChatApiV1ChatContinueSessionIdPost(
            sessionId,
            {
              message: message,
              id: currentPromptId,
            }
          );

        dispatch(
          feedbackChatActions.updatePendingBotMessage({
            text: response.llm_response.message,
            promptId: response.llm_response.id,
            individualId: selectedIndividualInfo.individualId,
          })
        );

        const currentUpdatedFeedback =
          response.currentUpdatedFeedback == null
            ? undefined
            : response.currentUpdatedFeedback;

        dispatch(
          feedbackChatActions.updateCurrentUpdatedFeedback({
            individualId: selectedIndividualInfo.individualId,
            currentUpdatedFeedback: currentUpdatedFeedback,
          })
        );

        dispatch(
          feedbackChatActions.updateSessionExpiration({
            individualId: selectedIndividualInfo.individualId,
            expirationTime: Date.now() + 24 * 60 * 60 * 1000,
            historyExpirationTime: Date.now() + 30 * 24 * 60 * 60 * 1000,
          })
        );
      } catch (error) {
        dispatch(
          feedbackChatActions.updatePendingBotMessage({
            text: 'Oops, something went wrong. Please try again.',
            individualId: selectedIndividualInfo.individualId,
          })
        );
        console.log(error);
      } finally {
        dispatch(
          feedbackChatActions.addMessageInputBox({
            individualId: selectedIndividualInfo.individualId,
          })
        );
      }
    },
    [
      currentPromptId,
      dispatch,
      expirationTime,
      selectedIndividualInfo,
      sessionId,
    ]
  );
};

export const useSelectIndividualCallback = () => {
  const dispatch = useAppDispatch();
  const startChat = useStartChatCallback();

  const {selectedIndividualInfo, sessions} =
    useAppSelector(getFeedbackChatState);

  return React.useCallback(
    (individualId?: IndividualId, individual?: Individual) => {
      if (selectedIndividualInfo?.individualId === individualId) {
        return;
      }

      if (individual != undefined && individualId != undefined) {
        const individualType = Object.hasOwn(individual, 'is_terminated')
          ? TypesOfIndividual.dropboxer
          : TypesOfIndividual.careerFrameworkIndividual;
        const individualInfo: SelectedIndividualInfo = {
          individualId: individualId,
          individualType: individualType,
        };
        dispatch(
          feedbackChatActions.updateSelectedIndividual({
            individualInfo,
          })
        );
      } else {
        dispatch(
          feedbackChatActions.updateSelectedIndividual({
            individualInfo: undefined,
          })
        );
      }

      if (!individualId) {
        return;
      }

      if (!sessions[individualId]) {
        startChat({
          individualId,
          individual,
        });
      }
    },
    [selectedIndividualInfo, dispatch, sessions, startChat]
  );
};

export const useHandleDropboxerAndCareerFrameworkSearchResultCallback = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (
      dropboxersResponse: DropboxerResponse[],
      careerFrameworkResponse: CareerFrameworkMetadata[]
    ) => {
      const dropboxerIds = dropboxersResponse.map(
        (dropboxer) => dropboxer.employee_id
      );

      const dropboxers = dropboxersResponse.reduce(
        (acc, dropboxer) => ({
          ...acc,
          [dropboxer.employee_id]: dropboxer,
        }),
        {} as Dropboxers
      );
      const cfIds = careerFrameworkResponse.map(
        (careerFramework) => careerFramework.cf_id
      );

      const careerFrameworkIndividuals = careerFrameworkResponse.reduce(
        (acc, careerFramework) => ({
          ...acc,
          [careerFramework.cf_id]: careerFramework,
        }),
        {} as CareerFrameworkIndividuals
      );

      dispatch(
        feedbackChatActions.replaceSearchResults({
          dropboxerIds,
          dropboxers,
          cfIds,
          careerFrameworkIndividuals,
        })
      );
    },
    [dispatch]
  );
};

export const useSetSearchQueryCallback = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (query: string) => {
      dispatch(feedbackChatActions.setSearchQuery({query}));
    },
    [dispatch]
  );
};

export const useSaveFeedbackAssistantSessions = () => {
  const saveToSessionStorage = React.useCallback(async (key, obj) => {
    sessionStorage.setItem(key, JSON.stringify(obj));
  }, []);

  const saveToSessionStorageThrottled = useThrottleAsyncCallback(
    saveToSessionStorage,
    1000
  );

  React.useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const sessions = getFeedbackAssistantSessions(store.getState());
      // Only save sessions with more than 2 messages
      const filteredSessions = Object.entries(sessions)
        /* eslint-disable @typescript-eslint/no-unused-vars */
        .filter(([_, session]) => session.messageIds.length > 2)
        /* eslint-enable @typescript-eslint/no-unused-vars */
        .reduce(
          (acc, [dropboxerId, session]) => ({
            ...acc,
            [dropboxerId]: session,
          }),
          {} as ReturnType<typeof getFeedbackAssistantSessions>
        );
      saveToSessionStorageThrottled(
        'feedback-assistant-sessions',
        filteredSessions
      );
    });
    return unsubscribe;
  }, [saveToSessionStorageThrottled]);
};

export const useGetIndividuals = () => {
  const {
    search: {
      careerFrameworkIndividuals: careerFrameworkIndividualsFromSearch,
      dropboxers: dropboxersFromSearch,
      query,
    },
    selectedIndividualInfo,
  } = useAppSelector(getFeedbackChatState);

  const careerFrameworkIndividualsWithSessions = useAppSelector(
    getCareerFrameworkIndividualsFromSessions
  );
  const dropboxersWithSessions = useAppSelector(getDropboxersFromSessions);
  const careerFrameworkIndividualsDisplayed =
    query.length < 3 ? 'sessions' : 'search';
  const dropboxersDisplayed = query.length < 3 ? 'sessions' : 'search';

  const careerFrameworkIndividuals =
    query.length < 3
      ? careerFrameworkIndividualsWithSessions
      : careerFrameworkIndividualsFromSearch;

  const dropboxers =
    query.length < 3 ? dropboxersWithSessions : dropboxersFromSearch;

  return {
    dropboxers,
    dropboxersDisplayed,
    careerFrameworkIndividuals,
    careerFrameworkIndividualsDisplayed,
    selectedIndividualInfo,
  };
};

export const useGetSearchBarStrings = (selectedIndividual?: Individual) => {
  const {
    search: {query},
  } = useAppSelector(getFeedbackChatState);

  const peopleViewTitle =
    query.length < 3 ? 'Recent Chats:' : 'Search Results:';

  if (
    selectedIndividual == undefined ||
    Object.hasOwn(selectedIndividual, 'is_terminated')
  ) {
    const selectedDropboxer = selectedIndividual
      ? (selectedIndividual as Dropboxer)
      : undefined;
    const selectedDropboxerName = selectedDropboxer
      ? `${selectedDropboxer.preferred_first_name} ${selectedDropboxer.preferred_last_name}`
      : undefined;

    const searchBarTitle = selectedDropboxerName
      ? `Dropboxer selected - ${selectedDropboxerName}`
      : `Dropboxer or Career Framework Track selector`;

    return {peopleViewTitle, searchBarTitle};
  } else {
    const selectedCareerFramework =
      selectedIndividual as CareerFrameworkIndividual;
    const searchBarTitle = `Career Framework selected - ${selectedCareerFramework.name}`;
    return {peopleViewTitle, searchBarTitle};
  }
};

export const useRemoveSessionCallback = () => {
  const dispatch = useAppDispatch();
  const {selectedIndividualInfo} = useAppSelector(getFeedbackChatState);

  return React.useCallback(
    (individualId: IndividualId) => {
      if (selectedIndividualInfo?.individualId === individualId) {
        dispatch(
          feedbackChatActions.updateSelectedIndividual({
            individualInfo: undefined,
          })
        );
      }
      dispatch(feedbackChatActions.removeSession({individualId}));
    },
    [dispatch, selectedIndividualInfo]
  );
};

import {Menu} from '@dropbox/dig-components/menu';
import {UIIcon} from '@dropbox/dig-icons';
import {OpenLine} from '@dropbox/dig-icons/assets';
import {GlobalHeader} from '@dropbox/dpc-ia';
import {DropboxClientContext} from 'App';
import SpritesGptBright from 'assets/SpritesGptBright.svg';
import SpritesGptDark from 'assets/SpritesGptDark.svg';
import {setToken} from 'components/auth/Auth';
import {ROUTE_PATHS} from 'constant';
import {getStormcrows} from 'helpers/DropboxApi';
import {useAuthUserIsAdmin} from 'hooks/use_is_admin';
import {useTheme} from 'hooks/use_theme';
import {useNavigateToTop} from 'hooks/useNavigateToTop';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useAppSelector} from 'store';
import {getPulseUser} from 'store/features/auth/selectors';
import {getDropboxService, getLoginService, LOGOUT_URL} from 'utilities';

import {ProfileAvatar} from './shared/ProfileAvatar';

export const FEEDBACK_URL =
  'https://dropbox.enterprise.slack.com/archives/C06559B53PX';

const SPRITES_GPT_GUIDE = 'https://dbx.link/spritesgpt-guide';
const WORKDAY = 'https://dbx.link/workday';

enum STORMCROW_FEATURE {
  APP_ENABLED = 'sprites_gpt_gate',
}

const STORMCROW_FEATURE_LIST = [STORMCROW_FEATURE.APP_ENABLED];

export const TopNavbar = () => {
  const {t} = useTranslation();
  const isDarkMode = useTheme() === 'dark';
  const navigate = useNavigateToTop();
  const curUser = useAppSelector(getPulseUser);
  const dropboxClient = React.useContext(DropboxClientContext);
  const isLoggedIn = curUser !== null;
  const isAdmin = useAuthUserIsAdmin();

  React.useEffect(() => {
    //Todo: remove this once we have a better way to handle app enrollment
    const checkAppEnabled = async () => {
      if (!dropboxClient) {
        return;
      }
      try {
        const stormcrowResult = await getStormcrows(
          dropboxClient,
          STORMCROW_FEATURE_LIST
        );
        const isAppEnabled =
          stormcrowResult[STORMCROW_FEATURE.APP_ENABLED] === 'ON';
        if (!isAppEnabled) {
          navigate('/waitlist');
        }
      } catch (error) {
        navigate('/404');
      }
    };

    checkAppEnabled();
  }, [dropboxClient, isLoggedIn, navigate]);

  const showAvatarMenu =
    curUser?.display_name && curUser?.abbreviated_name && curUser?.email;

  const handleLogout = () => {
    getLoginService()
      .routeLogoutAndRemoveCookieApiV1AuthLogoutGet()
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setToken('');
        window.location.href = LOGOUT_URL;
      });
  };

  const handleDropboxerRefresh = async () => {
    await getDropboxService().refreshAllDropboxersApiV1DropboxerRefreshPut();
  };

  const handleFeedbackRefresh = async () => {
    await getDropboxService().refreshAllFeedbackApiV1DropboxerRefreshFeedbackPut();
  };

  return (
    <GlobalHeader.Wrapper shouldAlwaysShowLogo>
      <Link to="/">
        <img src={isDarkMode ? SpritesGptDark : SpritesGptBright} />
      </Link>
      <GlobalHeader.UtilityNav>
        <GlobalHeader.Help>
          <Menu.Segment>
            <Menu.ActionItem
              onClick={() => {
                navigate(ROUTE_PATHS.PROFILE);
              }}
            >
              Opt-Out settings
            </Menu.ActionItem>
            <Menu.ActionItem
              href={FEEDBACK_URL}
              tagName="a"
              target="_blank"
              withRightAccessory={<UIIcon src={OpenLine} />}
            >
              Slack channel
            </Menu.ActionItem>
            <Menu.ActionItem
              href={SPRITES_GPT_GUIDE}
              tagName="a"
              target="_blank"
              withRightAccessory={<UIIcon src={OpenLine} />}
            >
              SPRiTEs-GPT Guide
            </Menu.ActionItem>
            <Menu.ActionItem
              href={WORKDAY}
              tagName="a"
              target="_blank"
              withRightAccessory={<UIIcon src={OpenLine} />}
            >
              Workday
            </Menu.ActionItem>
          </Menu.Segment>
          {isLoggedIn && isAdmin && (
            <Menu.Segment withLabel="Admin">
              <Menu.ActionItem onClick={handleDropboxerRefresh}>
                Import dropboxers from Workday
              </Menu.ActionItem>
              <Menu.ActionItem onClick={handleFeedbackRefresh}>
                Import feedback from Workday
              </Menu.ActionItem>
            </Menu.Segment>
          )}
        </GlobalHeader.Help>
        {isLoggedIn && showAvatarMenu && (
          <GlobalHeader.AccountMenu
            src={curUser?.profile_photo_url ?? undefined}
            initials={curUser?.abbreviated_name ?? undefined}
          >
            <Menu.Segment>
              <Menu.ActionItem
                withLeftAccessory={
                  <ProfileAvatar
                    abbreviated_name={curUser?.abbreviated_name ?? undefined}
                    display_name={curUser?.display_name || ''}
                    profile_photo_url={curUser?.profile_photo_url ?? undefined}
                  />
                }
                onClick={() => navigate(ROUTE_PATHS.PROFILE)}
                withSubtitle={curUser?.email}
                withTitle={curUser?.display_name}
              ></Menu.ActionItem>
            </Menu.Segment>
            <Menu.Segment>
              <Menu.ActionItem onClick={handleLogout}>
                {t('sign_out')}
              </Menu.ActionItem>
            </Menu.Segment>
          </GlobalHeader.AccountMenu>
        )}
      </GlobalHeader.UtilityNav>
    </GlobalHeader.Wrapper>
  );
};

import {Accordion} from '@dropbox/dig-components/accordion';
import {Avatar} from '@dropbox/dig-components/avatar';
import {Button} from '@dropbox/dig-components/buttons';
import {LabelGroup} from '@dropbox/dig-components/combinations';
import {List} from '@dropbox/dig-components/list';
import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Text, Title} from '@dropbox/dig-components/typography';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import cx from 'classnames';
import {ApiError, Quote} from 'client';
import {DropboxerCard} from 'components/manager_feedback/dropboxer_card';
import {FeedbackId, useGetFeedback} from 'components/manager_feedback/feedback';
import {atom, useAtom} from 'jotai';
import React from 'react';
import {DropboxerId} from 'store/features/feedback_chat/types';
import {getOpenAiService} from 'utilities';
import {v1 as uuidv1} from 'uuid';

import {FeedbackQuotesOptionMenu} from './feedback_quote_option_menu';
import styles from './manager_feedback.module.css';
import {spritesCycleAtom, useFeedbackCycles} from './sprites_cycle_selector';
import {TextBubble} from './text_bubble';

type FeedbackQuoteId = string;
export type CoreResponsibilityId = `${Quote.core_responsibility}`;
type QuoteType = `${Quote.type}`;

type FeedbackQuote = {
  quote: string;
  type: QuoteType;
  feedbackId: FeedbackId;
};

type FeedbackQuotesState = {
  byCoreResponsibility: {
    [id in CoreResponsibilityId]: FeedbackQuoteId[];
  };
  byFeedbackQuoteId: {
    [id in FeedbackQuoteId]: FeedbackQuote;
  };
};

const defaultFeedbackQuotesState: FeedbackQuotesState = {
  byCoreResponsibility: {
    results: [],
    direction: [],
    culture: [],
    talent: [],
    craft: [],
  },
  byFeedbackQuoteId: {},
};

const getFeedbackQuotes = async (
  dropboxerId: DropboxerId,
  spritesCycle: string | null
) => {
  const response =
    await getOpenAiService().getFeedbackQuotesApiV1FeedbackQuotesEmployeeIdGet(
      dropboxerId,
      spritesCycle
    );

  return response.quotes.reduce((prevState, quote) => {
    const quoteId = uuidv1();
    return {
      ...prevState,
      byCoreResponsibility: {
        ...prevState.byCoreResponsibility,
        [quote.core_responsibility]: [
          ...prevState.byCoreResponsibility[quote.core_responsibility],
          quoteId,
        ],
      },
      byFeedbackQuoteId: {
        ...prevState.byFeedbackQuoteId,
        [quoteId]: {
          quote: quote.text,
          type: quote.type,
          feedbackId: quote.feedback_id,
        },
      },
    };
  }, defaultFeedbackQuotesState);
};

export const useGetFeedbackQuotes = (dropboxerId: DropboxerId) => {
  const [spritesCycle] = useAtom(spritesCycleAtom);

  const {status, error, data, refetch, isRefetching} = useQuery({
    queryKey: ['/organize_feedback', dropboxerId, spritesCycle],
    queryFn: () => getFeedbackQuotes(dropboxerId, spritesCycle),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: (failureCount, err: ApiError) => {
      if (err.status === 403) {
        return false;
      }

      return failureCount < 3;
    },
  });

  if (status === 'loading') {
    return {
      status,
    };
  }

  if (status === 'error') {
    return {
      status,
      error: error as ApiError,
      refetch,
      isRefetching,
    };
  }

  return {
    status,
    feedbackQuotes: data,
  };
};

export const useSetFeedbackQuotesCallback = (dropboxerId: DropboxerId) => {
  const queryClient = useQueryClient();
  const {feedbackQuotes} = useGetFeedbackQuotes(dropboxerId);
  const [spritesCycle] = useAtom(spritesCycleAtom);

  return React.useCallback(
    (
      fn: (prevState?: FeedbackQuotesState) => FeedbackQuotesState | undefined
    ) =>
      queryClient.setQueryData(
        ['/organize_feedback', dropboxerId, spritesCycle],
        fn(feedbackQuotes)
      ),
    [dropboxerId, feedbackQuotes, queryClient, spritesCycle]
  );
};

const getFeedbackSummary = async (
  dropboxerId: DropboxerId,
  spritesCycle: string | null
) => {
  const response =
    await getOpenAiService().getFeedbackSummaryApiV1FeedbackSummaryEmployeeIdGet(
      dropboxerId,
      spritesCycle
    );

  return response.summary;
};

export const useGetFeedbackSummary = (dropboxerId: DropboxerId) => {
  const [spritesCycle] = useAtom(spritesCycleAtom);
  const {status, error, data, refetch, isRefetching} = useQuery({
    queryKey: ['/feedback_summary', dropboxerId, spritesCycle],
    queryFn: () => getFeedbackSummary(dropboxerId, spritesCycle),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: (failureCount, err: ApiError) => {
      if (err.status === 403) {
        return false;
      }

      return failureCount < 3;
    },
  });

  if (status === 'loading') {
    return {
      status,
    };
  }

  if (status === 'error') {
    return {
      status,
      error: error as ApiError,
      refetch,
      isRefetching,
    };
  }

  return {
    status,
    feedbackSummary: data,
    refetch,
    isRefetching,
  };
};

type FeedbackQuotesControllerProps = {
  dropboxerId: DropboxerId;
};

export const CORE_RESPONSIBILITIES: CoreResponsibilityId[] = [
  'results',
  'direction',
  'culture',
  'talent',
  'craft',
];

type FeedbackAccordionBladeIds = CoreResponsibilityId | 'summary';

const FEEDBACK_ACCORDION_BLADES: FeedbackAccordionBladeIds[] = [
  'summary',
  ...CORE_RESPONSIBILITIES,
];

export const FEEDBACK_ACCORDION_TITLES: Record<
  FeedbackAccordionBladeIds,
  string
> = {
  summary: 'Summary',
  results: 'Results',
  direction: 'Direction',
  culture: 'Culture',
  talent: 'Talent',
  craft: 'Craft',
};

const expandedFeedbackAccordionIds = atom<FeedbackAccordionBladeIds[]>([
  'summary',
]);

type FeedbackAccordionProps = {
  [key in CoreResponsibilityId | 'summary']: React.ReactNode;
};

const FeedbackAccordion = (props: FeedbackAccordionProps) => {
  const [expandedPanels, setExpandedPanels] = useAtom(
    expandedFeedbackAccordionIds
  );

  const handleHeaderClick = (cr: FeedbackAccordionBladeIds) => {
    setExpandedPanels((prev) => {
      if (prev.includes(cr)) {
        return prev.filter((panel) => panel !== cr);
      }

      return [...prev, cr];
    });
  };

  return (
    <Accordion variant="standard" expandedPanels={expandedPanels}>
      {FEEDBACK_ACCORDION_BLADES.map((cr) => (
        <Accordion.Item itemId={cr} key={cr}>
          <Accordion.Header size="large" onClick={() => handleHeaderClick(cr)}>
            <Accordion.HeaderContent
              withTitle={FEEDBACK_ACCORDION_TITLES[cr]}
            />
          </Accordion.Header>
          <Accordion.Panel>{props[cr]}</Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const FEEDBACK_QUOTE_TYPE_TITLE: Record<QuoteType, string> = {
  strength: 'Strengths',
  development_area: 'Developmental Areas',
};

const FEEDBACK_QUOTE_TYPES: QuoteType[] = ['strength', 'development_area'];

type FeedbackQuotesListProps = {
  coreResponsibility: CoreResponsibilityId;
  dropboxerId: DropboxerId;
};

const FeedbackQuotesList = ({
  coreResponsibility,
  dropboxerId,
}: FeedbackQuotesListProps) => {
  const {
    status: feedbackQuotesStatus,
    feedbackQuotes,
    error: feedbackQuotesError,
    refetch: refetchFeedbackQuotes,
    isRefetching: areFeedbackQuotesRefetching,
  } = useGetFeedbackQuotes(dropboxerId);

  const {
    status: feedbackStatus,
    feedbackState,
    feedbackGiversState,
    refetch: refetchFeedback,
    isRefetching: isFeedbackRefetching,
  } = useGetFeedback(dropboxerId);

  if (feedbackQuotesStatus === 'loading' || feedbackStatus === 'loading') {
    return <Spinner size="large" />;
  }

  if (feedbackQuotesStatus === 'error') {
    const errorMessage =
      feedbackQuotesError.status === 403
        ? feedbackQuotesError.body.detail
        : 'Error loading feedback quotes';

    return (
      <div className={cx(styles['generic-container'])}>
        <Text variant="paragraph" tagName="p" size="xlarge">
          {errorMessage}
        </Text>
        <Button
          variant="primary"
          onClick={() => refetchFeedbackQuotes()}
          isLoading={areFeedbackQuotesRefetching}
        >
          Retry
        </Button>
      </div>
    );
  }

  if (feedbackStatus === 'error') {
    return (
      <div className={cx(styles['generic-container'])}>
        <Text variant="paragraph" tagName="p" size="xlarge">
          Error loading feedback.
        </Text>
        <Button
          variant="primary"
          onClick={() => refetchFeedback()}
          isLoading={isFeedbackRefetching}
        >
          Retry
        </Button>
      </div>
    );
  }

  const quoteIds = feedbackQuotes.byCoreResponsibility[coreResponsibility];

  return (
    <List spacing="xsmall">
      {FEEDBACK_QUOTE_TYPES.map((quoteType) => {
        return (
          <React.Fragment key={`${coreResponsibility}-${quoteType}`}>
            <List.Label
              withText={
                <Title size="small">
                  {FEEDBACK_QUOTE_TYPE_TITLE[quoteType]}
                </Title>
              }
            />
            {quoteIds
              .filter(
                (quoteId) =>
                  feedbackQuotes.byFeedbackQuoteId[quoteId].type === quoteType
              )
              .map((quoteId) => {
                const {quote, feedbackId} =
                  feedbackQuotes.byFeedbackQuoteId[quoteId];
                const {feedbackGiverId} =
                  feedbackState.byFeedbackId[feedbackId];
                const feedbackGiver =
                  feedbackGiversState.byFeedbackGiverId[feedbackGiverId];

                return (
                  <List.Item key={quoteId}>
                    <List.Accessory
                      className={styles['feedback-quote-accessory']}
                    >
                      <FeedbackQuotesOptionMenu
                        quoteId={quoteId}
                        dropboxerId={dropboxerId}
                      />
                    </List.Accessory>
                    <List.Content>
                      <DropboxerCard dropboxer={feedbackGiver} />
                      <TextBubble text={quote} />
                    </List.Content>
                  </List.Item>
                );
              })}
          </React.Fragment>
        );
      })}
    </List>
  );
};

const FeedbackSummary = ({dropboxerId}: {dropboxerId: DropboxerId}) => {
  const {status, feedbackSummary, error, refetch, isRefetching} =
    useGetFeedbackSummary(dropboxerId);

  const {selectedSpritesCycle} = useFeedbackCycles(dropboxerId);

  if (status === 'loading') {
    return <Spinner size="large" />;
  }

  if (status === 'error') {
    const errorMessage =
      error.status === 403
        ? error.body.detail
        : 'Error loading feedback summary.';
    return (
      <div className={cx(styles['generic-container'])}>
        <Text variant="paragraph" tagName="p" size="xlarge">
          {errorMessage}
        </Text>
        <Button
          variant="primary"
          onClick={() => refetch()}
          isLoading={isRefetching}
        >
          Retry
        </Button>
      </div>
    );
  }

  return (
    <>
      <Text>
        This is a summary of <b>{selectedSpritesCycle || ''}</b> from Anytime
        Feedback, Diet SPRiTEs, and SPRiTEs. To choose a different cycle, use
        the SPRiTEs Cycle menu on the left.
      </Text>
      <List>
        <List.Item>
          <List.Content>
            <LabelGroup
              withLeftAccessory={<Avatar hasNoOutline>{'AI'}</Avatar>}
              withText={'SPRiTEs-GPT'}
              withSubtext="Disclaimer: Managers must not make decisions based on the AI-generated summary. They are fully responsible for reading all feedback, considering their own observations, and creating a thoughtful summary for the review."
            />
            <TextBubble html={feedbackSummary} />
          </List.Content>
        </List.Item>
      </List>
    </>
  );
};

export const FeedbackQuotesController = ({
  dropboxerId,
}: FeedbackQuotesControllerProps) => {
  const accordionCrProps = CORE_RESPONSIBILITIES.reduce((prevState, cr) => {
    return {
      ...prevState,
      [cr]: (
        <FeedbackQuotesList
          coreResponsibility={cr}
          dropboxerId={dropboxerId}
          key={`${dropboxerId}-${cr}`}
        />
      ),
    };
  }, {} as Omit<FeedbackAccordionProps, 'summary'>);

  return (
    <div className={cx(styles['feedback-container'])}>
      <FeedbackAccordion
        {...accordionCrProps}
        summary={
          <FeedbackSummary dropboxerId={dropboxerId} key={dropboxerId} />
        }
      />
    </div>
  );
};

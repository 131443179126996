import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {
  CareerFrameworkIndividuals,
  CFId,
  DropboxerId,
  Dropboxers,
  FeedbackChatState,
  Individual,
  IndividualId,
  SelectedIndividualInfo,
} from 'store/features/feedback_chat/types';
import {v1 as uuidv1} from 'uuid';

const SELECT_DROPBOXER_TEXT = `Welcome, choose any Dropboxer or pick a Career Framework Track to begin!`;

const defaultState: FeedbackChatState = {
  sessions: {
    default: {
      messageIds: ['select_dropboxer'],
      messages: {
        select_dropboxer: {
          type: 'bot_message',
          text: SELECT_DROPBOXER_TEXT,
        },
      },
      currentUpdatedFeedback: '',
      expirationTime: Infinity,
      historyExpirationTime: Infinity,
    },
  },
  selectedIndividualInfo: undefined,
  search: {
    query: '',
    dropboxerIds: [],
    dropboxers: {},
    cfIds: [],
    careerFrameworkIndividuals: {},
  },
};

const initialState: FeedbackChatState = {
  ...defaultState,
  sessions: {
    ...defaultState.sessions,
    ...JSON.parse(
      sessionStorage.getItem('feedback-assistant-sessions') ?? '{}'
    ),
  },
};

const newPendingBotMessage = (
  state: FeedbackChatState,
  action: PayloadAction<{individualId: IndividualId}>
): void => {
  const newMessageId = uuidv1();

  state.sessions[action.payload.individualId].messageIds.push(newMessageId);
  state.sessions[action.payload.individualId].messages[newMessageId] = {
    type: 'bot_message',
  };
};

const updatePendingBotMessage = (
  state: FeedbackChatState,
  action: PayloadAction<{
    individualId: IndividualId;
    text: string;
    promptId?: string;
  }>
): void => {
  const session = state.sessions[action.payload.individualId];
  const messageIds = session.messageIds;
  const messages = session.messages;

  const pendingBotMessageId = messageIds.find(
    (messageId) =>
      messages[messageId].type === 'bot_message' &&
      messages[messageId].text === undefined
  );

  if (!pendingBotMessageId) {
    return;
  }

  messages[pendingBotMessageId].text = action.payload.text;
  if (action.payload.promptId) {
    session.currentPromptId = action.payload.promptId;
  }
};

const updateCurrentUpdatedFeedback = (
  state: FeedbackChatState,
  action: PayloadAction<{
    individualId: IndividualId;
    currentUpdatedFeedback?: string;
  }>
): void => {
  const session = state.sessions[action.payload.individualId];
  session.currentUpdatedFeedback = action.payload.currentUpdatedFeedback;
};

const addMessageInputBox = (
  state: FeedbackChatState,
  action: PayloadAction<{
    individualId: IndividualId;
  }>
): void => {
  const session = state.sessions[action.payload.individualId];

  const inputMessageId = session.messageIds.find((messageId) => {
    return session.messages[messageId].type === 'unsent_message';
  });

  if (inputMessageId) {
    return;
  }

  const newMessageId = uuidv1();
  session.messageIds.push(newMessageId);
  session.messages[newMessageId] = {
    type: 'unsent_message',
  };
};

const editUnsentUserMessage = (
  state: FeedbackChatState,
  action: PayloadAction<{individualId: IndividualId; message: string}>
): void => {
  const session = state.sessions[action.payload.individualId];

  const inputMessageId = session.messageIds.find((messageId) => {
    return session.messages[messageId].type === 'unsent_message';
  });

  if (!inputMessageId) {
    return;
  }

  session.messages[inputMessageId].text = action.payload.message;
};

const markUnsetMessageAsSent = (
  state: FeedbackChatState,
  action: PayloadAction<{
    individualId: IndividualId;
  }>
): void => {
  const session = state.sessions[action.payload.individualId];

  const inputMessageId = session.messageIds.find((messageId) => {
    return session.messages[messageId].type === 'unsent_message';
  });

  if (!inputMessageId) {
    return;
  }

  session.messages[inputMessageId].type = 'user_message';
};

const removeUnsentMessage = (
  state: FeedbackChatState,
  action: PayloadAction<{individualId: IndividualId}>
): void => {
  const session = state.sessions[action.payload.individualId];

  const inputMessageId = session.messageIds.find((messageId) => {
    return session.messages[messageId].type === 'unsent_message';
  });

  if (!inputMessageId) {
    return;
  }

  delete session.messages[inputMessageId];
  session.messageIds = session.messageIds.filter(
    (messageId) => messageId !== inputMessageId
  );
};

const newSession = (
  state: FeedbackChatState,
  action: PayloadAction<{
    sessionId?: string;
    careerFrameworkId?: string;
    previousCareerFrameworkId?: string;
    promptId?: string;
    firstMessageText?: string;
    individual?: Individual;
    individualId: IndividualId;
  }>
): void => {
  const messageId = uuidv1();
  state.sessions[action.payload.individualId] = {
    sessionId: action.payload.sessionId,
    currentPromptId: action.payload.promptId,
    messageIds: [messageId],
    messages: {
      [messageId]: {
        text: action.payload.firstMessageText,
        type: 'bot_message',
      },
    },
    individual: action.payload.individual,
    careerFrameworkId: action.payload.careerFrameworkId,
    previousCareerFrameworkId: action.payload.previousCareerFrameworkId,
  };
};

const updateSelectedIndividual = (
  state: FeedbackChatState,
  action: PayloadAction<{individualInfo?: SelectedIndividualInfo}>
): void => {
  state.selectedIndividualInfo = action.payload.individualInfo;
};

const replaceSearchResults = (
  state: FeedbackChatState,
  action: PayloadAction<{
    dropboxerIds: DropboxerId[];
    dropboxers: Dropboxers;
    cfIds: CFId[];
    careerFrameworkIndividuals: CareerFrameworkIndividuals;
  }>
): void => {
  state.search.dropboxerIds = action.payload.dropboxerIds;
  state.search.dropboxers = action.payload.dropboxers;
  state.search.cfIds = action.payload.cfIds;
  state.search.careerFrameworkIndividuals =
    action.payload.careerFrameworkIndividuals;
};

const updateSessionExpiration = (
  state: FeedbackChatState,
  action: PayloadAction<{
    individualId: IndividualId;
    expirationTime: number;
    historyExpirationTime: number;
  }>
): void => {
  state.sessions[action.payload.individualId].expirationTime =
    action.payload.expirationTime;
  state.sessions[action.payload.individualId].historyExpirationTime =
    action.payload.historyExpirationTime;
};

const setSearchQuery = (
  state: FeedbackChatState,
  action: PayloadAction<{query: string}>
): void => {
  state.search.query = action.payload.query;
};

const removeSession = (
  state: FeedbackChatState,
  action: PayloadAction<{individualId: IndividualId}>
): void => {
  delete state.sessions[action.payload.individualId];
};

const feedbackChatSlice = createSlice({
  name: 'FEEDBACK_CHAT',
  initialState,
  reducers: {
    addMessageInputBox,
    editUnsentUserMessage,
    markUnsetMessageAsSent,
    newPendingBotMessage,
    newSession,
    replaceSearchResults,
    removeUnsentMessage,
    removeSession,
    setSearchQuery,
    updatePendingBotMessage,
    updateSelectedIndividual,
    updateSessionExpiration,
    updateCurrentUpdatedFeedback,
  },
});

export const feedbackChatActions = feedbackChatSlice.actions;
export const feedbackChatReducer = feedbackChatSlice.reducer;

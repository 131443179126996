import {Link, Text, Title} from '@dropbox/dig-components/typography';
import styles from 'components/career_frameworks/career_framework.module.css';
import React from 'react';
import Markdown from 'react-markdown';
import {LiComponent} from 'react-markdown/lib/ast-to-react';

const A = ({href, children}: {href?: string; children: React.ReactNode}) => {
  if (!href) {
    return null;
  }

  return <Link href={href}>{children}</Link>;
};

const H1 = ({children}: {children?: React.ReactNode}) => {
  return (
    <Title size="large" weightVariant="emphasized">
      {children}
    </Title>
  );
};

const H2 = ({children}: {children?: React.ReactNode}) => {
  return (
    <Title
      size="medium"
      weightVariant="emphasized"
      className={styles['markdown-h']}
    >
      {children}
    </Title>
  );
};

const H3 = ({children}: {children?: React.ReactNode}) => {
  return (
    <Title
      size="medium"
      weightVariant="normal"
      className={styles['markdown-h']}
    >
      {children}
    </Title>
  );
};

const P = ({children}: {children?: React.ReactNode}) => {
  return (
    <Text variant="paragraph" size="large" className={styles['markdown-p']}>
      {children}
    </Text>
  );
};

const STRONG = ({children}: {children?: React.ReactNode}) => {
  return <strong>{children}</strong>;
};

const EM = ({children}: {children?: React.ReactNode}) => {
  return <em className={styles['markdown-em']}>{children}</em>;
};

const UL = ({children}: {children?: React.ReactNode}) => {
  return <ul className={styles['markdown-ul']}>{children}</ul>;
};

const LI: LiComponent = ({children}) => {
  return (
    <li>
      <Text size="large">{children}</Text>
    </li>
  );
};

export const CareerFramework = ({markdown}: {markdown: string}) => {
  return (
    <Markdown
      components={{
        a: A,
        h1: H1,
        h2: H2,
        h3: H3,
        p: P,
        ul: UL,
        li: LI,
        strong: STRONG,
        em: EM,
      }}
    >
      {markdown}
    </Markdown>
  );
};

import {Avatar} from '@dropbox/dig-components/avatar';
import {LabelGroup} from '@dropbox/dig-components/combinations';
import {List} from '@dropbox/dig-components/list';
import {atom, useAtom} from 'jotai';
import React from 'react';

import {useGetFeedback} from './feedback';
import {getSpriteCycleOfFeedback} from './utils';

type SpritesCycleSelectorProps = {
  dropboxerId: string;
};

export const spritesCycleAtom = atom<string | null>(null);

const getSpritesYear = (cycle: string) => {
  return cycle.match(/\d{4}/g)?.[0];
};

export const useFeedbackCycles = (dropboxerId: string) => {
  const {feedbackState} = useGetFeedback(dropboxerId);
  const [selectedSpritesCycle, setSelectedSpritesCycle] =
    useAtom(spritesCycleAtom);

  const spriteCycles = new Set<string>();
  feedbackState?.allFeedbackIds.forEach((feedbackId) => {
    const {type, date} = feedbackState.byFeedbackId[feedbackId];
    const spriteCycle = getSpriteCycleOfFeedback(type, date);
    if (spriteCycle) {
      spriteCycles.add(spriteCycle);
    }
  });
  const sortedSpritesCycles = Array.from(spriteCycles).sort().reverse();

  React.useEffect(() => {
    if (sortedSpritesCycles.length === 0) {
      setSelectedSpritesCycle(null);
    }

    if (
      selectedSpritesCycle === null ||
      !sortedSpritesCycles.includes(selectedSpritesCycle)
    ) {
      setSelectedSpritesCycle(sortedSpritesCycles[0]);
    }
  }, [sortedSpritesCycles, selectedSpritesCycle, setSelectedSpritesCycle]);

  const handleSetSpritesCycle = (cycle: string) => {
    if (sortedSpritesCycles.includes(cycle)) {
      setSelectedSpritesCycle(cycle);
    }
  };

  return {sortedSpritesCycles, selectedSpritesCycle, handleSetSpritesCycle};
};

export const SpritesCycleSelector = ({
  dropboxerId,
}: SpritesCycleSelectorProps) => {
  const {sortedSpritesCycles, selectedSpritesCycle, handleSetSpritesCycle} =
    useFeedbackCycles(dropboxerId);

  return (
    <List isSelectable>
      {sortedSpritesCycles.map((cycle) => (
        <List.Item
          key={cycle}
          onClick={() => handleSetSpritesCycle(cycle)}
          isSelected={selectedSpritesCycle === cycle}
        >
          <List.Content>
            <LabelGroup
              withText={cycle}
              withLeftAccessory={
                <Avatar size="large">{getSpritesYear(cycle)?.slice(-2)}</Avatar>
              }
            />
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

import {Button, IconButton} from '@dropbox/dig-components/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {List} from '@dropbox/dig-components/list';
import {TextArea} from '@dropbox/dig-components/text_fields';
import {Link, Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CopyLine,
  EditLine,
  SendLine,
  UndoLine,
} from '@dropbox/dig-icons/assets';
import {DisclaimerType} from 'client';
import {useGetCareerFrameworkContent} from 'components/career_frameworks/career_framework_controller';
import {DisclaimerModal} from 'components/disclaimer_modal/disclaimer_modal';
import {
  useEditMessageCallback,
  useSendMessageCallback,
  useStartChatCallback,
} from 'components/feedback_chat/chat_hooks';
import styles from 'components/feedback_chat/feedback_chat.module.css';
import {
  AIMessage,
  displayCopyAlert,
  DropboxSearchBarController,
  MessageAvatar,
  StartOverModal,
  UserMessage,
} from 'components/feedback_chat/feedback_chat_utils';
import {ROUTE_PATHS} from 'constant';
import {useNavigateToTop} from 'hooks/useNavigateToTop';
import React from 'react';
import {useAppSelector} from 'store';
import {getFeedbackChatState} from 'store/features/feedback_chat/selectors';

const CurrentUpdatedFeedbackModal = ({
  isOpen,
  setIsOpen,
  onConfirmClick,
  currentUpdatedFeedback,
}: {
  isOpen: boolean;
  promptId?: string;
  setIsOpen: (open: boolean) => void;
  onConfirmClick: () => void;
  currentUpdatedFeedback?: string;
}) => {
  const currentUpdatedFeedbackText =
    currentUpdatedFeedback ||
    'No feedback provided for processing. Please provide SPRiTEsGPT with your feedback to see the updated version here!';

  const isCopyDisabled = currentUpdatedFeedback ? false : true;

  return (
    <Modal open={isOpen}>
      <Modal.Header>
        <Modal.Title>Current Formatted Feedback:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextArea
          resizable="auto"
          rows={1}
          readOnly
          value={currentUpdatedFeedbackText}
          onCopy={displayCopyAlert}
        />
      </Modal.Body>
      <Modal.Footer className={styles['button-container']}>
        <Button
          className={styles['button-without-margin']}
          variant="filled"
          onClick={() => {
            setIsOpen(false);
          }}
          autoFocus
        >
          Close
        </Button>
        <Button
          variant="primary"
          disabled={isCopyDisabled}
          onClick={() => {
            onConfirmClick();
          }}
        >
          Copy to Clipboard
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const FeedbackChatV2Controller = () => {
  const {selectedIndividualInfo, sessions} =
    useAppSelector(getFeedbackChatState);

  const {
    messageIds,
    messages,
    sessionId,
    currentPromptId,
    individual,
    currentUpdatedFeedback,
    careerFrameworkId,
    previousCareerFrameworkId,
  } = sessions[selectedIndividualInfo?.individualId ?? 'default'];
  const handleEditMessage = useEditMessageCallback(
    selectedIndividualInfo?.individualId
  );
  const handleSendMessage = useSendMessageCallback({
    sessionId,
    currentPromptId,
  });
  const handleStartChat = useStartChatCallback();

  const unsentMessageRef = React.useRef<HTMLTextAreaElement>(null);

  const [isStartOverModalOpen, setIsStartOverModalOpen] = React.useState(false);
  const [
    isCurrentUpdatedFeedbackModelOpen,
    setIsCurrentUpdatedFeedbackModelOpen,
  ] = React.useState(false);
  const onClickCopy = () => {
    displayCopyAlert();
    if (currentUpdatedFeedback) {
      navigator.clipboard.writeText(currentUpdatedFeedback);
    }
  };

  const navigateToTop = useNavigateToTop();
  const {name: careerFrameworkName} =
    useGetCareerFrameworkContent(careerFrameworkId);
  const {name: previousCareerFrameworkName} = useGetCareerFrameworkContent(
    previousCareerFrameworkId
  );

  // Only show the Feedback Button after a few messages have been sent.
  const shouldShowFeedbackButton = messageIds.length >= 6;

  const shouldShowCareerFramework = !!careerFrameworkId;
  const shouldShowPreviousCareerFramework = !!previousCareerFrameworkId;

  const careerFrameworkText = shouldShowCareerFramework && (
    <Text>
      Using the{' '}
      <Link
        href={`${window.location.origin}${ROUTE_PATHS.CAREER_FRAMEWORK}?cf=${careerFrameworkId}`}
        onClick={(e) => {
          e.preventDefault();
          navigateToTop(
            `${ROUTE_PATHS.CAREER_FRAMEWORK}?cf=${careerFrameworkId}`
          );
        }}
        variant="monochromatic"
        isBold
      >
        {careerFrameworkName}
      </Link>{' '}
      career framework as context for this feedback session.
      {!shouldShowPreviousCareerFramework &&
        ' If you think a different framework is more appropriate, you can use it by entering it in the search bar above.'}
    </Text>
  );

  const previousCareerFrameworkText = shouldShowCareerFramework &&
    shouldShowPreviousCareerFramework && (
      <Text>
        {' '}
        This employee&apos;s previous role corresponds to the{' '}
        <Link
          href={`${window.location.origin}${ROUTE_PATHS.CAREER_FRAMEWORK}?cf=${previousCareerFrameworkId}`}
          onClick={(e) => {
            e.preventDefault();
            navigateToTop(
              `${ROUTE_PATHS.CAREER_FRAMEWORK}?cf=${previousCareerFrameworkId}`
            );
          }}
          variant="monochromatic"
          isBold
        >
          {previousCareerFrameworkName}
        </Link>{' '}
        career framework. If you think this framework is more appropriate you
        can use it by entering it in the search bar above.
      </Text>
    );

  return (
    <>
      <StartOverModal
        isOpen={isStartOverModalOpen}
        setIsOpen={setIsStartOverModalOpen}
        onConfirmClick={() => {
          handleStartChat({
            individualId: selectedIndividualInfo?.individualId,
            individual,
          });
        }}
      />
      <CurrentUpdatedFeedbackModal
        isOpen={isCurrentUpdatedFeedbackModelOpen}
        setIsOpen={setIsCurrentUpdatedFeedbackModelOpen}
        onConfirmClick={onClickCopy}
        currentUpdatedFeedback={currentUpdatedFeedback}
      />
      <List
        verticalAlign="top"
        className={styles['chat-container']}
        key={selectedIndividualInfo?.individualId ?? 'default'}
      >
        {careerFrameworkText}
        {previousCareerFrameworkText}
        {messageIds.map((messageId) => {
          const messageType = messages[messageId].type;
          const messageText = messages[messageId].text;

          const isBotMessage = messageType === 'bot_message';
          const isUserMessage = !isBotMessage;
          const isUnsentMessage = messageType === 'unsent_message';

          return (
            <List.Item key={messageId}>
              <List.Accessory>
                <MessageAvatar messageType={messageType} />
              </List.Accessory>
              <List.Content>
                {isBotMessage && <AIMessage text={messageText} />}
                {isUserMessage && (
                  <UserMessage
                    handleEditMessage={handleEditMessage}
                    handleSendMessage={handleSendMessage}
                    messageText={messageText ?? ''}
                    messageType={messageType}
                    unsentMessageRef={unsentMessageRef}
                  />
                )}
              </List.Content>
              {isUnsentMessage && (
                <List.Accessory className={styles['send-button']}>
                  <IconButton
                    variant="transparent"
                    onClick={() => {
                      messageText && handleSendMessage(messageText);
                    }}
                    disabled={messageText === undefined || messageText === ''}
                    size="large"
                  >
                    <UIIcon src={SendLine} />
                  </IconButton>
                </List.Accessory>
              )}
            </List.Item>
          );
        })}
        {selectedIndividualInfo?.individualId && (
          <List.Item>
            <List.Content className={styles['button-container-chat']}>
              <Button
                variant="filled"
                onClick={() => {
                  setIsStartOverModalOpen(true);
                }}
                withIconEnd={<UIIcon src={UndoLine} />}
              >
                Start Over
              </Button>
              {shouldShowFeedbackButton && (
                <Button
                  href={'https://dbx.link/spritesgptfeedback'}
                  variant="filled"
                  target="_blank"
                  withIconEnd={<UIIcon src={EditLine} />}
                >
                  Write feedback for SPRiTEsGPT
                </Button>
              )}
              <Button
                variant="filled"
                onClick={() => {
                  setIsCurrentUpdatedFeedbackModelOpen(true);
                }}
                withIconEnd={<UIIcon src={CopyLine} />}
              >
                View Formatted Feedback
              </Button>
            </List.Content>
          </List.Item>
        )}
      </List>
    </>
  );
};

export const FeedbackChatV2 = () => {
  return (
    <div className={styles['page']}>
      <DisclaimerModal disclaimerType={DisclaimerType.STAKEHOLDER_FEEDBACK} />
      <DropboxSearchBarController />
      <FeedbackChatV2Controller />
    </div>
  );
};

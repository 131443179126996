import './App.css';
import './index.css';
import '@dropbox/dig-components/dist/index.web.css';
import '@dropbox/dpc-ia/index.css';
import '@dropbox/dig-fonts/dist/index.min.css';
import '@dropbox/dig-fonts/dist/sharp_grotesk_23.min.css';
import './translations/i18n';

import {Dropbox} from '@dropbox/api-v2-client';
import {Modal} from '@dropbox/dig-components/modal';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {setToken} from 'components/auth/Auth';
import {getAPIForLoggedInUser} from 'helpers/api';
import {createContext, useEffect, useState} from 'react';
import {RouterProvider} from 'react-router-dom';
import {router} from 'routes';
import {authActions} from 'store/features/auth';
import {useAppDispatch} from 'store/hooks';
import {getAuthService} from 'utilities';

import styles from './App.module.css';

// 100vh and 100vw goes beyond the actual window.innerHeight and
// window.innerWidth values. Instead of 100vh and 100vw, we should
// use var(--full-inner-height) and var(--full-inner-width).
const getFullWindowDimensionsCss = () => {
  return `:root {--full-inner-height:${window.innerHeight}px;--full-inner-width:${window.innerWidth}px}`;
};
const windowDimensionsVariables = document.createElement('style');
windowDimensionsVariables.innerHTML = getFullWindowDimensionsCss();
window.addEventListener('resize', () => {
  windowDimensionsVariables.innerHTML = getFullWindowDimensionsCss();
});
document.body.appendChild(windowDimensionsVariables);

export const DropboxClientContext = createContext<Dropbox | null>(null);

const root = document.getElementById('root');

if (root) {
  Modal.setAppElement(root);
}

const loadCurrentUser = async () => {
  try {
    const user = await getAuthService().readUserMeApiV1AuthMeGet();

    const dropboxClient = await getAPIForLoggedInUser(user.token);

    return {user, dropboxClient};
  } catch {
    return {user: null, dropboxClient: null};
  }
};

const queryClient = new QueryClient();

const App = () => {
  const [dropboxClient, setDropboxClient] = useState<Dropbox | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let cancel = false;

    loadCurrentUser().then(({user, dropboxClient: client}) => {
      if (!cancel) {
        setDropboxClient(client);
        dispatch(authActions.setPulseUser(user));
        setToken(user?.pulse_token ?? '');
      }
    });

    return () => {
      cancel = true;
    };
  }, [dispatch]);

  return (
    <DropboxClientContext.Provider value={dropboxClient}>
      <QueryClientProvider client={queryClient}>
        <div className={styles.app}>
          <RouterProvider router={router} />
        </div>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </DropboxClientContext.Provider>
  );
};

export default App;
